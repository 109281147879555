<template>
  <v-card>
    <v-container>
      <v-row>
        <v-col class="col-4">
          <v-list-item three-line>
            <v-list-item-avatar tile size="60" color="grey"></v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="headline">
                ｘｘｘ医院 集客施策
              </v-list-item-title>
              <v-list-item-subtitle>
                医療法人○○○
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col class="col-8">
          <v-slider
            v-if="!value"
            v-model="progress"
            :color="progressColor"
            track-color="gray"
            :tick-labels="['ヒアリング前', '初回ヒアリング', '確認中', 'FIX']"
            :max="3"
            ticks
            thumb-label="always"
            class="pa-8"
          >
            <template v-slot:thumb-label="{value}">
              <v-icon v-if="value == 0" color="white">mdi-phone</v-icon>
              <v-icon v-if="value == 1" color="white">mdi-account-edit-outline</v-icon>
              <v-icon v-if="value == 2" color="white">mdi-account-switch-outline</v-icon>
              <v-icon v-if="value == 3" color="white">mdi-account-heart-outline</v-icon>
            </template>
          </v-slider>

          <v-stepper v-if="value == 1" alt-labels style="box-shadow: none">
            <v-stepper-header>
              <v-stepper-step step="1">
                ヒアリング前
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="2">
                確認中
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">
                FIX
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>

        <v-slider
          v-if="value == 2" 
          value="38"
          label="進捗率"
          :thumb-size="24"
          thumb-label="always"
          class="mt-8"
        >
        </v-slider>

        <v-container v-if="value == 3">
          <v-row>
            <v-col cols="3">
              <div class="d-flex flex-column align-center">
                <v-subheader>BPシート</v-subheader>
                <v-avatar tile color="orange" size="48" class="white--text headline">FIX</v-avatar>
              </div>
            </v-col>
            <v-col cols="3">
              <div class="d-flex flex-column align-center">
                <v-subheader>戦略設計</v-subheader>
                <v-avatar tile color="green" size="48" class="white--text headline">途中</v-avatar>
              </div>
            </v-col>
            <v-col cols="3">
              <div class="d-flex flex-column align-center">
                <v-subheader>ユーザ理解</v-subheader>
                <v-avatar tile color="green" size="48" class="white--text headline">途中</v-avatar>
              </div>
            </v-col>
            <v-col cols="3">
              <div class="d-flex flex-column align-center">
                <v-subheader>デザイン要件</v-subheader>
                <v-avatar tile color="grey" size="48" class="white--text headline">未</v-avatar>
              </div>
            </v-col>
          </v-row>
        </v-container>

        </v-col>
      </v-row>
      <v-divider></v-divider>
    </v-container>
  </v-card>
</template>
<script>
export default {
  props: {
  value: {type: Number, default: 3}
  },
  data() {
    return {
      progress: 2,
    };
  },
  computed: {
      progressColor () {
        if (this.progress == 0) return 'grey'
        if (this.progress == 1) return 'indigo'
        if (this.progress == 2) return 'green'
        if (this.progress == 3) return 'orange'
        return 'red'
      },
  },
}
</script>
