<template>
  <v-card>
    <sample-project-summary :value="2"></sample-project-summary>
    <br>
    <sample-project-summary :value="1"></sample-project-summary>
    <br>
    <sample-project-summary :value="3"></sample-project-summary>
    <br>
  </v-card>
</template>
<script>
import SampleProjectSummary from './SampleProjectSummary'
export default {
  components: {SampleProjectSummary}
}
</script>